import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Gallery from "../GetGallery"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

export const query = graphql`
  query($directory: String!) {
    allFile(
      filter: { relativeDirectory: { eq: $directory } }
      sort: { fields: childImageSharp___fluid___originalName, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              src
              aspectRatio
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Project = ({ data }) => {
  const project = data.allFile.edges

  return (
    <section>
      <Container>
        <Row>
          <Col xs={12}>
            <Link to="projects#projects-page">Return To Projects Page</Link>
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Gallery
            itemsPerRow={[1, 2, 3]}
            images={project.map(image => ({
              ...image.node.childImageSharp.fluid,
            }))}
          />
        </Row>
      </Container>
    </section>
  )
}

export default Project
